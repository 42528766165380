<template>
  <div class="BusinessArea">
    <Head @closeMenu="closeOpenMenu" />
    <Menu ref="menu" />
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
    <div class="banner">
      <!-- <div class="forward">
          </div> -->
    </div>
    <div class="contents">
      <div style="width: 100%; height: 730px; background-color: #56595bcc">
        <div class="contentCenter">
          <h3 class="title">业务领域</h3>
          <p class="lines">
            <span class="lineCenter"></span>
          </p>

          <div class="contextCenter">
            <el-row :gutter="10">
              <!-- :xs="24" :sm="8" :md="12" :lg="12" :xl="12" -->
              <el-col :span="12">
                <div class="con" style="width: 724px">
                  <div class="img" style="width: 712px">
                    <img
                      src="../../assets/image/BusinessArea/business1.png"
                      alt=""
                    />
                  </div>
                  <p class="txt">
                    计算与人工智能园区建设及运营
                  </p>
                </div>
              </el-col>
              <!-- :xs="24" :sm="8" :md="6" :lg="6" :xl="6" -->
              <el-col :span="6">
                <div class="con">
                  <div class="img">
                    <img
                      src="../../assets/image/BusinessArea/business3.png"
                      alt=""
                    />
                  </div>
                  <p class="txt">公共计算服务平台建设及运营</p>
                </div>
              </el-col>
              <!-- :xs="24" :sm="8" :md="6" :lg="6" :xl="6" -->
              <el-col :span="6">
                <div class="con">
                  <div class="img">
                    <img
                      src="../../assets/image/BusinessArea/business4.png"
                      alt=""
                    />
                  </div>
                  <p class="txt">计算中心及数据中心建设</p>
                </div>
              </el-col>
              <!-- :xs="24" :sm="8" :md="12" :lg="12" :xl="12" -->
              <el-col :span="12" style="margin-top: 20px">
                <div class="con" style="width: 724px">
                  <div class="img" style="width: 712px">
                    <img
                      src="../../assets/image/BusinessArea/business2.png"
                      alt=""
                    />
                  </div>
                  <p class="txt">
                    数字应用系统建设（数字应用、智慧智能设计与平台建设）
                  </p>
                </div>
              </el-col>
              <!-- :xs="24" :sm="8" :md="6" :lg="6" :xl="6" -->
              <el-col :span="6" style="margin-top: 20px">
                <div class="con">
                  <div class="img">
                    <img
                      src="../../assets/image/BusinessArea/business5.png"
                      alt=""
                    />
                  </div>
                  <p class="txt">数据产业园区商业管理与运营</p>
                </div>
              </el-col>
              <!-- :xs="24" :sm="8" :md="6" :lg="6" :xl="6" -->
              <el-col :span="6" style="margin-top: 20px">
                <div class="con">
                  <div class="img">
                    <img
                      src="../../assets/image/BusinessArea/business6.png"
                      alt=""
                    />
                  </div>
                  <p class="txt">数字基础设施建设 <span style="font-size:14px;">(ICT、物联网、云计算)</span></p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div class="bottomCotent">
        <div class="bottomCenter">
          <h2>项目动态</h2>
          <ul class="projectList">
            <li>
              <div class="left"></div>
              <div class="right">
                <router-link to="/Imageexhibition">
                  <h3>有为信通护航“云游非遗·影像展”</h3>
                  <div>2021-06-11</div>
                  <p>
                    6月11日，2021年文化和自然遗产日“云游非遗·影像展”“非遗购物节”启动仪式在京举行。文化和旅游部党组书记、部长胡和平出席活动并讲话，部党组成员王晓峰出席活动。此次活动，主办方选用华为视频会议设备全程进行视频直播，有为信通为会议活动直播，提供相关设备和技术支持，保障了直播顺利进行，画面清晰、流畅，操作便捷、智能，专业度可圈可点！···
                  </p>
                </router-link>
              </div>
            </li>
            <li>
              <div class="left"></div>
              <div class="right">
                <router-link to="/Housewarming">
                  <h3>华为智能协作体验中心揭幕仪式</h3>
                  <div>2021-05-25</div>
                  <p>
                    2021年5月25日，有为信通乔迁之喜暨华为智能协作体验中心揭幕仪式在公司新址隆重举行，华为领导和公司领导共同为公司乔迁之喜剪彩，并宣布公司正式入驻新办公区（北京市丰台科技园盈坤世纪D座8层），华为领导还为公司的“华为北京机器视觉好望学堂能力中心”举行了授牌仪式，现场掌声热烈。···
                  </p>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
export default {
  components: {
    Head,
    Menu,
    Topreturn,
    Footer,
  },
  methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
};
</script>

<style scoped>
/* banner图开始 */
.banner {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/BusinessArea/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.banner .forward {
  width: 164px;
  height: 44px;
  background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 58px;
  bottom: 26px;
}
/* banner图结束 */

/* 主体内容开始 */
.contents {
  width: 100%;
  height: 1180px;
  background-image: url("../../assets/image/BusinessArea/mainBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position: 0px -201px; */
}
.contents .contentCenter {
  padding-top: 78px;
  color: #fff;
  max-width: 1466px;
  margin: 0 auto;
}
.contents .contentCenter .title {
  font-size: 40px;
  font-family: IOS9W4;
  font-weight: bold;
  color: #ffffff;
}
.contents .contentCenter .lines {
  margin-bottom: 36px;
}
.contents .contentCenter .lines .lineCenter {
  width: 70px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  display: inline-block;
}
.contents .contentCenter .contextCenter {
  color: #333333;
  display: flex;
  /* padding-top: 37px; */
  justify-content: space-between;
}
.contents .contentCenter .contextCenter .con {
  width: 354px;
  height: 230px;
  background: #f5f5f5;
  text-align: center;
  border: 1px #ccc;
  display: table-cell;
  vertical-align: middle;
}
.contents .contentCenter .contextCenter .con .img {
  width: 344px;
  height: 182px;
  margin: 0 auto;
  overflow: hidden;
}
.contents .contentCenter .contextCenter .con .img img {
  /* width: 96%; */
  /* width: 344px;
     height: 182px; */
  overflow: hidden;
}
.contents .contentCenter .contextCenter .con .txt {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-top: 6px;
}
.contents .bottomCotent {
  width: 100%;
  height: 450px;
  background-color: #000000cc;
}
.contents .bottomCotent .bottomCenter {
  padding-top: 48px;
  color: #fff;
  max-width: 1466px;
  margin: 0 auto;
}
.contents .bottomCotent .bottomCenter h2 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f5f5f5;
  line-height: 25px;
  text-align: left;
}
.contents .bottomCotent .bottomCenter .projectList li {
  display: flex;
  padding-top: 37px;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  border-bottom: 1px dashed #3c4047;
}
.contents .bottomCotent .bottomCenter .projectList li .left {
  height: 98px;
  width: 98px;
  background-image: url("../../assets/image/BusinessArea/dynamic1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.contents .bottomCotent .bottomCenter .projectList li:nth-child(1) .left {
  background-image: url("../../assets/image/BusinessArea/dynamic2.png");
}
.contents .bottomCotent .bottomCenter .projectList li .right {
  width: 1328px;
  height: 98px;
  text-align: left;
}
.contents .bottomCotent .bottomCenter .projectList li .right h3 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.contents .bottomCotent .bottomCenter .projectList li .right div {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: right;
  margin-top: -30px;
}
.contents .bottomCotent .bottomCenter .projectList li .right p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
/* hover 事件 */
.contents .contentCenter .contextCenter .con:hover .img img {
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  -webkit-transition: 0.35s;
  transition: 0.35s;
}
.contents .bottomCotent .bottomCenter .projectList li:hover .left {
  background-size: 105% 105%;
  transition: 0.5s;
}
.contents .bottomCotent .bottomCenter .projectList li:hover .right h3 ,.contents .bottomCotent .bottomCenter .projectList li:hover .right p {
  /* color: #6c35e4 !important; */
      -moz-transform: scale(1.01, 1.01);
    -webkit-transform: scale(1.01, 1.01);
    -o-transform: scale(1.01, 1.01);
    transform: scale(1.01, 1.01);
    -webkit-transition: .35s;
    transition: .35s;
}
</style>